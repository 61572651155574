import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql,navigate, withPrefix } from "gatsby";
import Helmet from "react-helmet";
import {
    FaFacebookF,
    FaTelegramPlane,
    FaWhatsapp,
    FaTwitter,
} from "react-icons/fa";
import { useCookies } from "react-cookie";

import Contact from "../components/Contact";
import ProfileForm from "../components/common/ProfileForm";
import Loading from "../components/common/Loading";
import NotifyButton from "../components/Contact/NotifyButton";
import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";
import { isInPaytm } from "../utils/paytm";

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ data, location }) => {
    const post = data.ghostPost;
    const [cookies, setCookie] = useCookies([`loggedIn`]);

    useEffect(() => {
        if (!cookies.loggedIn){
            navigate(`/`);
        }
    },[]);

    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
                <script src={withPrefix('removeShare.js')} type="text/javascript" />
            </Helmet>
            <Layout>
                <div className="container">
                    <article className="content">
                        <h1 className="content-title" align="center">
                            {post.title}
                        </h1>
                        {post.feature_image ? (
                            <figure className="post-full-image" align="center">
                                <img
                                    src={post.feature_image}
                                    alt={post.title}
                                />
                            </figure>
                        ) : null}
                        <section className="post-full-content">
                            <br />

                            {/* The main post content */}
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                    </article>
                </div>
                {/* <div className="container" align="center">
                    <a href={`http://www.facebook.com/sharer.php?u=` + post.url + `%3Futm_source%3DwebShare%26utm_medium%3Dfacebook`} target="_blank" rel="noopener noreferrer" style={{ margin: `10px`, fontSize:`36px` }}><span title="Share on facebook"><FaFacebookF style={{ fontSize: `40px`, color: `#3b5998` }}/></span></a>
                    <a href={`https://telegram.me/share/url?url=` + post.url + `%3Futm_source%3DwebShare%26utm_medium%3Dtelegram`} target="_blank" rel="noopener noreferrer" style={{ margin: `10px`, fontSize:`36px` }}><span title="Send on telegram"><FaTelegramPlane style={{ fontSize: `40px`, color: `#34ACE1` }}/></span></a>
                    <a href={`https://api.whatsapp.com/send?text=` + encodeURI(post.title + ` ` + post.url) + `%3Futm_source%3DwebShare%26utm_medium%3Dwhatsapp`} style={{ margin: `10px`, fontSize:`36px` }} target="_blank" rel="noopener noreferrer" ><span title="Send on whatsapp"><FaWhatsapp style={{ fontSize: `40px`, color: `#25d366` }} /></span></a>
                    <a href={`https://twitter.com/intent/tweet?text=` + encodeURI(post.title + ` ` + post.url + `?utm_source=webShare&utm_medium=twitter` + ` via @finshots`)} target="_blank" rel="noopener noreferrer" style={{ margin: `10px`, fontSize:`36px` }}><span title="Share on twitter"><FaTwitter style={{ fontSize: `40px`, color: `#1da1f2` }} /></span></a>
                    
                    <br /><br />
            	</div> */}
                {/* <Contact /> */}
            </Layout>
        </>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
            url: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Post;

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`;
